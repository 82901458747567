import { createApp } from 'vue'
import Index from './Index.vue'
import router from './router'
import VueScrollTo from 'vue-scrollto'

// Mock Server for Development Enviroment
// if (process.env.NODE_ENV === 'development') {
//   const { worker } = require('./mocks/browser')
//   worker.start()
// }

createApp(Index)
  .use(router)
  .use(VueScrollTo)
  .mount('#app')

require('@/assets/scss/reset.scss')
require('@/assets/scss/layout/style.scss')
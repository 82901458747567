import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import NewsListView from '../views/NewsListView.vue'
import NewsSingleView from '../views/NewsSingleView.vue'
import PeopleView from '../views/PeopleView.vue'
import RobotsView from '../views/RobotsView.vue'
import EpisodeView from '../views/EpisodeView.vue'
import ProductsListView from '../views/ProductsListView.vue'
import ProductsSingleView from '../views/ProductsSingleView.vue'
import FaqView from '../views/FaqView.vue'
import PrivacyView from '../views/PrivacyView.vue'
import ContactView from '../views/ContactView.vue'
import NotFoundErrorView from '../views/NotFoundErrorView.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
    meta: {
      title: '株式会社フレアオリジナル - 産業・協働ロボットシステム開発・販売 - 長野県坂城町',
      desc: '株式会社フレアオリジナルのコーポレートサイトです。弊社は、産業・協働ロボットの構想ご提案から仕様決定、設計、製造、納品、保守、販売までを一貫してお客様に提供しています。このサイトでは10を超える取扱メーカーや特徴、社員エピソードなどをご覧いただけます。',
    },
    beforeEnter: (to, from, next) => {
      next();
    }
  },
  {
    path: '/news',
    name: 'newsList',
    component: NewsListView,
    meta: {
      title: 'お知らせ - 株式会社フレアオリジナル - 産業・協働ロボットシステム開発・販売 - 長野県坂城町', 
      desc: '株式会社フレアオリジナルからのお知らせをご覧いただくことができます。',
    }
  },
  {
    path: '/news/:newsID',
    name: 'newsSingle',
    component: NewsSingleView,
    meta: {
      title: 'お知らせ - 株式会社フレアオリジナル - 産業・協働ロボットシステム開発・販売 - 長野県坂城町', 
      desc: '株式会社フレアオリジナルからのお知らせをご覧いただくことができます。',
    }
  },
  {
    path: '/people',
    name: 'people',
    component: PeopleView,
    meta: {
      title: '「人」からフレアオリジナルを知る - 株式会社フレアオリジナル - 産業・協働ロボットシステム開発・販売 - 長野県坂城町', 
      desc: '弊社で何よりも大事にしていること。それは「人」です。私たちがどのような想いでロボットの開発、販売、コンサルティングを行っているのかをご覧いただくことができます。',
    }
  },
  {
    path: '/people/:episodeID',
    name: 'episodes',
    component: EpisodeView,
    meta: {
      title: '社員エピソード - 株式会社フレアオリジナル - 産業・協働ロボットシステム開発・販売 - 長野県坂城町', 
      desc: '弊社で働くメンバーのエピソードをご覧いただくことができます。',
    },
  },
  {
    path: '/robots',
    name: 'robots',
    component: RobotsView,
    meta: {
      title: '「ロボット」からフレアオリジナルを知る - 株式会社フレアオリジナル - 産業・協働ロボットシステム開発・販売 - 長野県坂城町', 
      desc: '弊社の事業の柱である「ロボットシステムの設計・製造・開発」、「ロボット製品の販売」、「Robotics Laboの運営・実施」、「自動化コンサルティング」についてご覧いただくことができます。' 
    }
  },
  {
    path: '/robots/products',
    name: 'productsList',
    component: ProductsListView,
    meta: {
      title: '取り扱い製品一覧 - 株式会社フレアオリジナル - 産業・協働ロボットシステム開発・販売 - 長野県坂城町',
      desc: 'フレアオリジナルで取り扱っているなロボット一覧をご覧いただくことができます。' 
    }
  },
  {
    path: '/robots/products/:productID',
    name: 'productsSingle',
    component: ProductsSingleView,
    meta: {
      title: '取り扱い製品 - 株式会社フレアオリジナル - 産業・協働ロボットシステム開発・販売 - 長野県坂城町',
      desc: 'フレアオリジナルで取り扱っているなロボット一覧をご覧いただくことができます。' 
    },
  },
  {
    path: '/faq',
    name: 'faq',
    component: FaqView,
    meta: {
      title: 'よくある質問 - 株式会社フレアオリジナル - 産業・協働ロボットシステム開発・販売 - 長野県坂城町',
      desc: '弊社で聞かれることの多いQ&Aを掲載しています。' 
    },
  },
  {
    path: '/privacy',
    name: 'privacy',
    component: PrivacyView,
    meta: {
      title: 'プライバシーポリシー - 株式会社フレアオリジナル - 産業・協働ロボットシステム開発・販売 - 長野県坂城町',
      desc: '株式会社フレアオリジナルのコーポレートサイトです。弊社は、産業・協働ロボットの構想ご提案から仕様決定、設計、製造、納品、保守、販売までを一貫してお客様に提供しています。このサイトでは10を超える取扱メーカーや特徴、社員エピソードなどをご覧いただけます。' 
    },
  },
  {
    path: '/contact',
    name: 'contact',
    component: ContactView,
    meta: {
      title: 'お問い合わせ - 株式会社フレアオリジナル - 産業・協働ロボットシステム開発・販売 - 長野県坂城町',
      desc: 'フレアオリジナルへのお問い合わせはこちらのページよりお願いします。' 
    },
  },

  // 404
  {
    path: '/:catchAll(.*)',
    name: 'notfound',
    component: NotFoundErrorView,
    meta: {
      title: 'ページが見つかりません - 株式会社フレアオリジナル - 産業・協働ロボットシステム開発・販売 - 長野県坂城町'
    }
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to) {
    if (to.hash) {
      return { el: to.hash, behavior: 'smooth' };
    } else {
      return new Promise((resolve) => {
        setTimeout(() => {
          resolve({ left: 0, top: 0})
        }, 10)
      })
    }
  },
  mode: 'history',
  base: process.env.BASE_URL
})

export default router
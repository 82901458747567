<template>
<headerArea/>
  <router-view/>
<footerArea/>
</template>

<script>
import headerArea from './components/layout/headerArea.vue'
import footerArea from './components/layout/footerArea.vue'

export default {
  name: 'App',
  components: {
    headerArea,
    footerArea
  },
  methods : {
    setMeta(route){
      // タイトルを設定
      if(route.meta.title){
        let setTitle = route.meta.title;
        document.title = setTitle;
      }
      // ディスクリプションを設定
      if(route.meta.desc){
        let setDesc = route.meta.desc;
        document.querySelector("meta[name='description']").setAttribute('content', setDesc)
      }
    }
  },
  mounted(){
    let route = this.$route;
    this.setMeta(route);
  },
  watch: { 
    '$route' (route) {
      this.setMeta(route);
    }
  }
}

//-vh
const setVh = () => {
  const vh = window.innerHeight * 0.01;
  document.documentElement.style.setProperty('--vh', `${vh}px`);
};
window.addEventListener('load', setVh);
</script>

<style lang="scss">
@use '@/assets/scss/config/common' as c;
@use 'sass:math';

.v-enter-active, .v-leave-active {
  transition: opacity .5s;
}
.v-enter, .v-leave-to {
  opacity: 0;
}

.img {
  overflow: hidden;
  border-radius: 10px;
  position: relative;
  * {
    transform: scale(1.1);
    transition: transform 1.5s ease;
  }
  &:before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: c.$three_color;
    transition: right .3s ease;
    z-index: 9;
  }
  &.disp {
    * {
      transform: scale(1);
    }
    &:before {
      right: 100%;
    }
  }
}
</style>
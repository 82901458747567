import axios from "axios";

export async function getAllMedia(){
    // Headers
    const headers = {
        headers: { 
            "Content-Type" : "application/json" }
    };
    // API Call
    const response = await axios.get(`/json/posts.json`, headers)

    return response.data;
}

export async function getMediaByID(id){
    // Headers
    const headers = {
        headers: { 
            "Content-Type" : "application/json" }
    };
    // API Call
    const response = await axios.get(`/json/posts.json`, headers)
    return response.data.filter(
        function(data){ 
            return data.id == id;
        }
    )[0];
}

import axios from "axios";

export async function getNews(){
    // Headers
    const headers = {
        headers: { 
            "Content-Type" : "application/json" }
    };
    // File Call
    const response = await axios.get(`/json/news.json`, headers)
    return response.data;
}

export async function getNewsByID(newsID){
    // // Headers
    const headers = {
        headers: { 
            "Content-Type" : "application/json" }
    };
    // API Call
    const response = await axios.get(`/json/news.json`, headers)

    return response.data.filter(
        function(data){ 
            return data.id == newsID;
        }
    )[0];

}



export async function getProducts(categoryId){
    // Headers
    const headers = {
        headers: { 
            "Content-Type" : "application/json" }
    };

    // var url = "http://localhost:8888/products";
    // if(categoryId){
    //     url += "?category_ids="+categoryId;
    // }

    // API Call
    if(categoryId){
        const response = await axios.get(`/json/productsFilterCategory.json`, headers)
        return response.data;
    }else{
        const response = await axios.get(`/json/products.json`, headers)
        return response.data;
    }
    
}

export async function getProductByID(productID){
    // Headers
    const headers = {
        headers: { 
            "Content-Type" : "application/json" }
    };
    // API Call
    const response = await axios.get(`/json/products.json`, headers)

    return response.data.filter(
        function(data){ 
            return data.id == productID;
        }
    )[0];
}


